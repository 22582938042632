// sidemenu -----------------------------------------------------
var showOpen = false;
$(function() {
    jQuery('.logistics-nav-bar').click(function() {
        showOpen ? closeSideBar() : openSideBar();
    });
    jQuery('.logistics-shadow').click(function() {
        closeSideBar();
    });
    
    
})

function openSideBar() {
    jQuery('.logistics-nav-bar').find('div:nth-child(1)').animate({top: 8}, 300, function () {
        jQuery(this).addClass('line-t');
    });
    jQuery('.logistics-nav-bar').find('div:nth-child(2)').animate({opacity: 0}, 300);
    jQuery('.logistics-nav-bar').find('div:nth-child(3)').animate({top: -8}, 300, function () {
        jQuery(this).addClass('line-b');
    });
    jQuery('.logistics-shadow').fadeIn();
    jQuery('.sider-bar-logistics').slideDown();
    showOpen = true;
}
function closeSideBar() {
    jQuery('.logistics-nav-bar').find('div:nth-child(1)').removeClass('line-t').animate({top: 0}, 600);
    jQuery('.logistics-nav-bar').find('div:nth-child(2)').animate({opacity: 1}, 300);
    jQuery('.logistics-nav-bar').find('div:nth-child(3)').removeClass('line-b').animate({top: 1}, 600);
    jQuery('.sider-bar-logistics').slideUp();
    jQuery('.logistics-shadow').fadeOut();
    showOpen = false;
}

// popup form -----------------------------------------------------
function openEnquireForm() {
    $('#bookingModal').modal('show');
}
var ss, curChooseDate, curChooseTime;;
$(function() {

    ss = $('.bm-calendar').datetimepicker({
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: false,
        minDate: new Date(),
        locale: dpLang,
        daysOfWeekDisabled: [0, 7, 6],
    });

    
    if(ss.length){
        $.ajax({
            type: "POST",
            url: baseUrl+"getUnavailableDates",
            data: {action: 'consultation', operation: 'submit'},
            success: function(result){
                if(result && result.status == 200){
                    ss.data("DateTimePicker").disabledDates(result.data.dates);
                }
            },
            dataType: 'json'
        });
    }
    
    ss.on('dp.change', function(event) {
        curChooseDate = event.date.format('YYYY-MM-DD');
        if(!!curChooseDate) {
            $('.btn-choose-calendar').removeAttr('disabled')
        }
    })
    
    $('.bm-time-wrap button').click(function() {
        $(this).addClass('active').siblings('button').removeClass('active');
        curChooseTime = $(this).text();
        $('.btn-choose-time').removeAttr('disabled');
    })
    
    $('.bm-contact input').keyup(function() {
        if($('#txtPopName').val() !== '' && $('#txtPopShop').val() !== '' && $('#txtPopEmail').val() !== '' && $('#txtPopPhone').val() !== '') {
            $('.btn-choose-contact').removeAttr('disabled');
        } else {
            $('.btn-choose-contact').attr('disabled', true);
        }
    })
});


function stepToChooseTime() {
    stepToClassName('.choose-time-wrap');
}
function stepToClassName(className) {
    $('.booking-wrap > div').hide();
    $(className).show();
}
function handlePopupFormSubmit() {
    var reqData = {
        date: curChooseDate,
        time: curChooseTime,
        name: $('#txtPopName').val(),
        company: $('#txtPopShop').val(),
        email: $('#txtPopEmail').val(),
        phone: $('#txtPopPhone').val(),
        source: '',
    };
    if(typeof source !== 'undefined'){
        reqData.source = source;
    }
    $.ajax({
        url: baseUrl + "sumbitConsultation",
        method: "POST",
        data: reqData,
        dataType: 'json',
        success: function(response) {
            if(response.status == 200){
                window.location.replace(baseUrl + 'consultation-confirmation');
            }else{
                $message({
                    content: response.message
                });
            }
        }
    });
}

// main from -----------------------------------------------------
$(function() {
    
    $('#btnGetPrice').click(function() {
        var reqData = {
            name: $('.bmf-name').val(),
            email: $('.bmf-email').val(),
            phone: $('.bmf-phone').val(),
            company: $('.bmf-company').val(),
            date: $('.txt-in-date').val(),
            order_num: $('.bmf-order-num').val(),
            shipment_num: $('.bmf-shipment-num').val(), 
            interest_services: $('.bmf-services').val(), 
            product_category: $('.bm-category').val(), 
            targeted_delivery_preference: $('.bmf-target').val(), 
            how_did_you_find_us: $('.menu_find_us').val(), 
            current_platforms: $('.menu_current_use').val(), 
            source: '',
        };
        if(typeof source !== 'undefined'){
            reqData.source = source;
        }
        $.ajax({
            url: baseUrl + "sumbitQuotation",
            method: "POST",
            data: reqData,
            dataType: 'json',
            success: function(response) {
                if(response.status == 200){
                    // $('#thanksModal').modal('show');
                    window.location.replace(baseUrl + 'hktvmall-confirmation');
                }else{
                    $message({
                        content: response.message
                    });
                }
            }
        });
    });
    $('.bmf-name, .bmf-email, .bmf-phone, .bmf-company').keyup(function() {
        checkBMFForm();
    });
    $('.bmf-services, .bmf-target').change(function() {
        checkBMFForm();
    });
    function checkBMFForm() {
        if($('.bmf-name').val() != '' && $('.bmf-email').val() != '' && $('.bmf-phone').val() != '' && $('.bmf-company').val() != '' && $('.bmf-services').val() != '' && $('.bmf-target').val() != '') {
            $('#btnGetPrice').removeAttr('disabled');
        } else {
            $('#btnGetPrice').attr('disabled', true);
        }
    }
});

// bottom form ------------------------------
$(function() {
    
    $('#btnBottomGet').click(function() {
        var reqData = {
            name: $('.bo-name').val(),
            email: $('.bo-email').val(),
            phone: $('.bo-phone').val(),
            company: $('.bo-company').val(),
            interest_services: $('.bo-services').val(),
            how_did_you_find_us: $('.bo_find_us').val(), 
            current_platforms: $('.bo_current_use').val(), 
            source: ''
        };
        if(typeof source !== 'undefined'){
            reqData.source = source;
        }
        $.ajax({
            url: baseUrl + "sumbitQuotation",
            method: "POST",
            data: reqData,
            dataType: 'json',
            success: function(response) {
                if(response.status == 200){
                    if(source =='HKTVmall') {
                        window.location.replace(baseUrl + 'hktvmall-confirmation');
                    } else if(source == 'crowdfunding-fulfillment') {
                        window.location.replace(baseUrl + 'crowdfunding-confirmation');
                    } else if(source == 'crossborder-fulfillment') {
                        window.location.replace(baseUrl + 'crossborder-confirmation');
                    } else {
                        window.location.replace(baseUrl + 'quotation-confirmation');
                    }
                }else{
                    $message({
                        content: response.message
                    });
                }
            }
        });
    });
    $('.bo-name, .bo-email, .bo-phone, .bo-company').keyup(function() {
        checkBOForm();
    });
    $('.bo-services').change(function() {
        checkBOForm();
    });
    function checkBOForm() {
        if($('.bo-name').val() != '' && $('.bo-email').val() != '' && $('.bo-phone').val() != '' && $('.bo-company').val() != '' && $('.bo-services').val() != '') {
            $('#btnBottomGet').removeAttr('disabled');
        } else {
            $('#btnBottomGet').attr('disabled', true);
        }
    }
});

// menu form ------------------------------
$(function() {
    
    $('body').on('click', '#btnMenuGet', function() {
        var reqData = {
            name: $('.menu-name').val(),
            email: $('.menu-email').val(),
            phone: $('.menu-phone').val(),
            company: $('.menu-company').val(),
            interest_services: $('.menu-services').val(),
            how_did_you_find_us: $('.menu_find_us').val(),
            current_e_commerce_platforms: $('.menu_current_use').val(),
            source: ''
        };
        if(typeof source !== 'undefined'){
            reqData.source = source;
        }
        if(source == 'crowdfunding-fulfillment') {
            reqData.crowdfunding_campaign_link = $('.menu-link').val();
        }
        $.ajax({
            url: baseUrl + "sumbitQuotation",
            method: "POST",
            data: reqData,
            dataType: 'json',
            success: function(response) {
                if(source == 'crowdfunding-fulfillment') {
                    $('#crowdfundingMenuModal').modal('hide');
                } else {
                    $('#menuModal').modal('hide');
                }
                if(response.status == 200){
                    // $('#thanksModal').modal('show');
                    if(source =='HKTVmall') {
                        window.location.replace(baseUrl + 'hktvmall-confirmation');
                    } else if(source == 'crowdfunding-fulfillment') {
                        window.location.replace(baseUrl + 'crowdfunding-confirmation');
                    } else {
                        window.location.replace(baseUrl + 'quotation-confirmation');
                    }
                }else{
                    $message({
                        content: response.message
                    });
                }    
            }
        });
    });
    $('.menu-name, .menu-email, .menu-phone, .menu-company').keyup(function() {
        checkMenuForm();
    });
    $('.menu-services').change(function() {
        checkMenuForm();
    });
    function checkMenuForm() {
        if($('.menu-name').val() != '' && $('.menu-email').val() != '' && $('.menu-phone').val() != '' && $('.menu-company').val() != '' && $('.menu-services').val() != '') {
            $('#btnMenuGet').removeAttr('disabled');
        } else {
            $('#btnMenuGet').attr('disabled', true);
        }
    }
});
